import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import TTLogo from '../images/tt_logo.svg';
import Hamburger from '../images/hamburger.svg';

export const scrollToSection = (section) => {
  const yOffset = 0;
  const element = document.querySelector(`#${section}`);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  // element.scrollIntoView({behavior: "smooth"})

  window.scrollTo({top: y, behavior: 'smooth'});
}

const Menu = styled('nav')`
  position: fixed;
  width: 100vw;
  height: 92px;
  top: 0;
  right: 0;
  background: ${props => props.showBG ? 'hsla( 28, 72%, 57%, 0.9)' : 'hsla( 28, 72%, 57%, 0)'} ;
  color: ${props => props.showBG ? 'hsla( 28, 72%, 100%, 1)' : 'hsla( 28, 2%, 100%, 1)'} ;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 6px 12px  ${props => props.showBG ? 'hsla( 28, 72%, 27%, 0.6)' : 'transparent'};
  transition: all 0.3s ease-out;
  & span {
  margin-right: 36px;
  cursor:pointer;
  }
  @media screen and (max-width: 768px){
    overflow: hidden;
    padding-left: 96px;
    //justify-content: space-evenly;
    align-items: flex-end;
    padding-top: 24px;
    height: ${props => props.opened ? '120px' : '92px'};
    background: ${props => props.opened || props.showBG ? 'hsla( 28, 72%, 57%, 0.9)' : 'hsla( 28, 72%, 57%, 0)'} ;
    background-image: url(${Hamburger});
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: right 24px top 36px;
    & span {
      position: relative;
      margin-right: 8px;
      top: ${props => props.opened ? '-12px' : '48px'};
      transition: top 0.3s ease-out;
    }
  }
`
const Road = styled('svg')`
  position: fixed;
  width: 96px;
  height: 100vh;
  z-index: 3;
`;

const Header = ({scrollOffset, showBG, siteTitle }) => {
  const [ScrollOffset, setScrollOffset] = useState(0);
  const [NavOpened, setNavOpened] = useState(false)
  useEffect(()=>{
    window.addEventListener('scroll', () => setScrollOffset(window.pageYOffset))
  }, [])

  return (
    <>
      <Helmet script={[{ 
        type: 'text/javascript', 
        innerHTML: `
        !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
          (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
          h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8414350.js';
          h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
              
          snid('verify', '8414350');
        ` 
      }]}/>
      <Menu showBG={showBG} opened={NavOpened} onClick={()=>{setNavOpened(!NavOpened)}}>
        <span role="link" onClick={()=>{scrollToSection('shippers')}} onKeyUp={()=>{scrollToSection('shippers')}} tabIndex='0'>shippers</span>
        <span role="link" onClick={()=>{scrollToSection('providers')}} onKeyUp={()=>{scrollToSection('providers')}} tabIndex='0'>providers</span>
        <span role="link" onClick={()=>{scrollToSection('about')}} onKeyUp={()=>{scrollToSection('about')}} tabIndex='0'>about</span>
        <span role="link" onClick={()=>{scrollToSection('contact')}} onKeyUp={()=>{scrollToSection('contact')}} tabIndex='0'>contact</span>
        <span><a href={'https://app.alpha-tms.com'} title={"alpha-tms login"} target={'_blank'} rel='noopener noreferrer' style={{textDecoration: 'none', color: 'inherit'}}>login</a></span>
      </Menu>
      <Road viewBox={`0 0 96px 10000`} preserveAspectRatio="xMaxYMax meet">
        {/*<path fill={'#ccc'} d={'M0,0V100H48V-123.5l32,-32V-48Z'} />*/}
        <path fill={'#ccc'} d={'M0,0V10000H48V120L96,92V-92Z'} />
        <path fill={'transparent'} stroke={'#fff'} strokeWidth={2} strokeDasharray={30} strokeDashoffset={ScrollOffset/5} d={'M72,0V76L24,106V10000'} />
        <path fill={'hsl(204, 100%, 50%)'} d={'M0,0V92L48,64V0Z'} />
      </Road>
      <a href={'https://transporttender.nl'} title={"Transport Tender"} target={'_blank'} rel='noopener noreferrer'><img alt="Transport Tender logo" style={{zIndex: 3, width: '24px', height: '40px', position: 'fixed', left: '12px', top: '18px'}} src={TTLogo} /></a>
    </>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
