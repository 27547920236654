/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Header from "./header"
import "./layout.css"
import TTLogo from '../images/tt_logo.svg';

const Address = styled('address')`
  position:absolute;
  bottom: 3vmin;
  right: 3vmin;
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  line-height: 1.6;
  display: block;
  &:before{
    content: '';
    position: absolute;
    top: 6px;
    left: -64px;
    width: 48px;
    height: 84px;
    background-image: url(${TTLogo});
    background-repeat: no-repeat;
    background-size: contain;
  }
`


const Layout = ({ children, scrolled }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header showBG={scrolled} siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer style={{position: 'relative', marginTop: '50vh', zIndex: -1}}>
        <svg style={{transform: 'rotate(180deg)', marginBottom: '-10px', position: 'absolute', bottom: '0px'}} width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMaxYMax meet">
          <defs>
            <linearGradient id="gold-diagonal" gradientTransform="rotate(249, 0.5, 0.5)">
              <stop offset="0" stopColor="hsl(28, 100%, 35%)" stopOpacity="1" />
              <stop offset="1" stopColor="hsl(28, 70%, 66%)" stopOpacity="1" />
            </linearGradient>
          </defs>
          <path d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
          <path transform={"translate(0, -5)"} d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
          <path transform={"translate(0, -10)"} d="M0,0V48L10.75,54L100,0Z" fill="url('#gold-diagonal')" fillOpacity="0.55"/>
        </svg>
        <Address>
          <strong>Transport Tender</strong><br />
          <span>Leidse Schouw 2<br />2408 AE, Alphen a/d Rijn<br />The Netherlands</span><br />
          <span><a href="mailto:info@alpha-tms.com">info@alpha-tms.com</a></span><br />
          <span><a href="tel:+31172740090">+31 172 74 00 90</a></span><br />
          <span>ING NL78INGB0007984917</span><br />
          <span>CoC: 69905843</span><br />
          <span>VAT: NL8531.11.728.B.01</span><br />
        </Address>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
